





































import { computed, defineComponent, onMounted, ref, PropType } from '@nuxtjs/composition-api';
import { CtaInterface, RenderPoiSource, RichTextItemInterface } from '@amplience/types';
import CtaPartial from '@theme/components/molecules/CtaPartial.vue';

export default defineComponent({
  name: 'ImageHalfWidth',
  components: {
    CtaPartial,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: Array as PropType<RichTextItemInterface[]>,
      default: () => [],
    },
    cta: {
      type: Object,
      default: () => ({}),
    },
    contentPosition: {
      type: String,
      default: 'left',
    },
    imageHolder: {
      type: Object,
      default: () => ({}),
    },
    backgroundColour: {
      type: String,
      default: 'Neutral-0 - White'
    }
  },
  setup(props) {
    const isImageHolder = ():boolean => !!(props.imageHolder?.poi?.image);
    const isCallToAction = ref<boolean>(false);
    const ctaParams = ref<CtaInterface>({});

    ctaParams.value = {
      linkType: props.cta.linkType,
      title: props.cta.title || '',
      url: props.cta.url || '',
      type: props.cta.type ? props.cta.type : 'Secondary',
      newWindow: !!(props.cta.newWindow),
      noFollow: !!(props.cta.noFollow),
      trackingQuery: props.cta.trackingQuery || '',
      classes: 'half-width--cta',
    };

    onMounted(() => {
      isCallToAction.value = !!(props.cta?.url && props.cta?.title)
    });

    return {
      ctaParams,
      isImageHolder,
      isCallToAction,
      sources: computed(() => ([
        {
          aspect: '1:1',
          height: 382,
          media: '(max-width: 767px)',
        },
        {
          aspect: '1:1',
          height: 440,
          media: '(min-width: 768px)',
        },
      ] as RenderPoiSource[])),
    }
  }
})
